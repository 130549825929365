export type ShortHandLayer = {
  id: string;
  source: string;
  icon?: string;
  size?: number;
  anchor?: string;
  color?: string;
  opacity?: number;
  dash?: any;
}

export enum LAYERS {
  LOCATIONS = 'locations',
  POINTS = 'points',
  BOUNDS = 'bounds',
  OUTLINE = 'outline',
  ASSET_POINTS = 'asset-points',
  ASSET_HEADINGS = 'asset-headings',
  ASSET_CLUSTERS = 'asset-clusters',
  ASSET_CLUSTERS_COUNT = 'asset-clusters-count',
  VESSEL_LOCATIONS_AIS = 'vessel-locations-ais',
  AIS_REGION_VESSEL_LOCATION = 'ais-region-vessel-locations',
  AIS_REGION_LAYER_OUTLINE = 'ais-region-outline',
  VESSEL_LOCATIONS = 'vessel-locations',
  VESSEL_HEADINGS = 'vessel-headings',
  TRIP_VEHICLE_POINT = 'trip-vehicle-point',
  TRIP_VEHICLE_LINE = 'trip-vehicle-line',
  TRIP_LINE_MAP_MATCHED = 'trip-vehicle-line-map-matched',
  TRIP_START_POINT = 'trip-start-point',
  TRIP_END_POINT = 'trip-end-point',
  TRIP_DETAIL_POINT = 'trip-detail-point',
  TRIP_EVENTS_POINTS = 'trip-events-points',
  STATIC_ASSET_WELLHEAD_LOCATION = 'static-asset-wellhead-location',
  STATIC_ASSET_PIPEMONITOR_LOCATION = 'static-asset-pipemonitor-location',
  STATIC_ASSET_PIPELINE_CONNECTOR_BOUNDS = 'static-asset-pipeline-connector-bounds',
  STATIC_ASSET_PIPELINE_LINE_LAYER = 'static-asset-pipeline-line-layer',
  STATIC_ASSET_PIPELINE_LINE_ANIMATION_LAYER = 'static-asset-pipeline-line-animation-layer',
  ASSET_LABELS_MOVING = 'asset-labels-moving',
  ASSET_LABELS_STATIC = 'asset-labels-static',
  STATIC_ASSET_SECURITY_CAMERAS = 'security-cameras',
  STATIC_ASSET_SECURITY_CAMERAS_FOV = 'security-cameras-fov',
  CONSTANT_SHIPPING_LANE_LAYER = 'constant-shipping-lanes-layer',
  PLACES = 'places',
  PLACES_HIGHLIGHTED = 'places-highlitghted',
};

export default LAYERS;
