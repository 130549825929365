import { ScheduleSend } from '@mui/icons-material';
import { Button } from '@mui/material';
import AiDialog from 'components/AiDialog';
import FlashMessages from 'components/FlashMessages';
import HelpCenter from 'components/HelpCenter';
import InlineLoader from 'components/InlineLoader';
import NavigationProvider from 'components/Navigation';
import { PageContent } from 'components/Navigation/content';
import Notifications from 'components/Notifications';
import Onboarding from 'components/Onboarding';
import RightSideBar from 'components/RightSideBar';
import { ActionMenuContextProvider } from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import { UIContextProvider } from 'context/UIContext';
import { useAuth } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { useWebsockets } from 'services/useWebsockets';
import {
  AssetDetails,
  Assets,
  AssetsStatus,
  Drivers,
  Groups,
  Integrations,
  Locations,
  Login,
  Map,
  Profile,
  // MapGL as Map,
  Register,
  ReportDetails,
  Reports,
  ResetPassword,
  Roles,
  Settings,
  Users,
} from 'views';
import { VerifyWhatsApp } from 'views/Auth/VerifyWhatsApp';
import AISVesselsDashboard from 'views/Dashboard/AISVessels';
import VehicleDashboard from 'views/Dashboard/Vehicles';
import VesselDashboard from 'views/Dashboard/Vessels';
import DriverReport from 'views/Drivers';
import DriverProfile from 'views/Profiles/Driver';
import AssetLog from 'views/Reports/Assets/AssetLog';
import AssetFuel from 'views/Reports/Fuel';
import LocalSpeeding from 'views/Reports/LocalSpeeding';
import LocationActivity from 'views/Reports/Locations/LocationActivity';
import ScheduledReports from 'views/Reports/ScheduledReports';
import CrudScheduledReport from 'views/Reports/ScheduledReports/crud';
import Speeding from 'views/Reports/Speeding';
import Trips from 'views/Reports/Trips';
import Trip from 'views/Reports/Trips/Trip';
import AssetUtilization from 'views/Reports/Utilization';
import AISRegions from 'views/Settings/AISRegions';
import Billing from 'views/Settings/Billing';
import DriverBehavior from 'views/Settings/DriverBehavior';
import NotificationsPage from 'views/Settings/Notifications/index';
import { authEvents } from '../services/axios';
import { Dashboard } from '../views/Dashboard';
import Contacts from '../views/Settings/Contacts/index';

const WEBSOCKET_NAME = {
  ORG: 'organization',
  USER: 'user',
};

const AppRouting = () => {
  const { user, isLoading, logout } = useAuth();
  const { setPermissions, canRead } = usePermissions();
  const { orgConnected, userConnected } = useWebsockets(user);
  const { t } = useTranslation();

  const handleLogout = () => {
    logout();
    authEvents.off('logout', handleLogout);
  };

  React.useEffect(() => {
    if (!!user) {
      authEvents.on('logout', handleLogout);
    } else {
      authEvents.off('logout', handleLogout);
    }

    return () => {
      authEvents.off('logout', handleLogout);
    };
  }, [user]);

  React.useEffect(() => {
    if (!!user && (user.permissions !== null || user.isOwner)) {
      setPermissions(user.permissions, user.isOwner || false);
    }
  }, [user]);

  const isAuthenticated = () => {
    return !!user;
  };

  const getNavWrappedComponent = ({
    component,
    fullSize,
    pageTitle,
    titleMenu,
    hideHeader,
    rightSideBarAlwaysOpen = false,
    preventKeyPressDefault = false,
  }: {
    component: any;
    fullSize?: boolean;
    pageTitle?: string;
    titleMenu?: any;
    hideHeader?: boolean;
    rightSideBarAlwaysOpen?: boolean;
    preventKeyPressDefault?: boolean;
  }) => {
    return (
      <UIContextProvider
        defaultState={{
          rightSideBarAlwaysOpen,
          direction: 'ltr',
          isMobile: window.innerWidth <= 768,
        }}>
        <NavigationProvider />
        <ActionMenuContextProvider>
          <AiDialog />
          <HelpCenter />
          <Notifications />
          <Onboarding user={user} />
          <PageContent
            pageTitle={pageTitle}
            titleMenu={titleMenu}
            hideHeader={hideHeader}
            fullSize={fullSize}
            isMobile={window.innerWidth <= 768}>
            {component}
          </PageContent>
          <RightSideBar preventKeyPressDefault={preventKeyPressDefault} />
          <FlashMessages />
        </ActionMenuContextProvider>
      </UIContextProvider>
    );
  };

  if (isLoading) {
    return (
      <Router>
        <Routes>
          <Route
            path='*'
            element={
              <div
                style={{
                  height: '100dvh',
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <InlineLoader />
              </div>
            }
          />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <Routes>
        {!isAuthenticated() ? (
          <>
            <Route path='/' element={<Login />} />
            <Route
              path='/whatsapp_verify/:token'
              element={<VerifyWhatsApp />}
            />
            <Route path='/forgot-password' element={<ResetPassword />} />
            <Route path='/register' element={<Register initial />} />
            <Route
              path='/user/register/:userId/:registrationId'
              element={<Register confirmation />}
            />
            <Route
              path='/user/:userId/forgot-password/:forgotPasswordSecret'
              element={<Register forgotPassword />}
            />
            <Route path='*' element={<Navigate to='/' replace />} />
          </>
        ) : (
          <>
            <Route
              path='/whatsapp_verify/:token'
              element={<VerifyWhatsApp />}
            />
            <Route
              path='/forgot-password'
              element={<Navigate to='/' replace />}
            />
            <Route path='/register' element={<Navigate to='/' replace />} />
            <Route
              path='/user/register/:userId/:registrationId'
              element={<Navigate to='/' replace />}
            />
            <Route
              path='/user/:userId/forgot-password/:forgotPasswordSecret'
              element={<Navigate to='/' replace />}
            />
            <Route
              path='/'
              element={getNavWrappedComponent({
                component: <Map />,
                fullSize: true,
                rightSideBarAlwaysOpen: false,
                preventKeyPressDefault: true,
              })}
            />
            <Route path='/register' element={<Navigate to='/' replace />} />

            {/* <Route
              path='/locations'
              element={getNavWrappedComponent({
                component: <Locations />,
                pageTitle: 'Locations',
                rightSideBarAlwaysOpen: true,
              })}
            />
            <Route
              path='/groups'
              element={getNavWrappedComponent({
                component: <Groups />,
                pageTitle: 'Groups',
                rightSideBarAlwaysOpen: true,
              })}
            />

            <Route
              path='/assets'
              element={getNavWrappedComponent({
                component: <Assets />,
                pageTitle: 'Assets',
                rightSideBarAlwaysOpen: true,
              })}
            />

            <Route
              path='/drivers'
              element={getNavWrappedComponent({
                component: <Drivers />,
                pageTitle: 'Drivers',
                rightSideBarAlwaysOpen: true,
              })}
            /> */}

            <Route
              path='/dashboard/*'
              element={getNavWrappedComponent({
                component: <Dashboard />,
                rightSideBarAlwaysOpen: false,
                hideHeader: true,
              })}>
              <Route path='vehicles' element={<VehicleDashboard />} />

              <Route path='ais_vessels' element={<AISVesselsDashboard />} />

              <Route path='vessels' element={<VesselDashboard />} />
            </Route>

            <Route
              path='/assets/:id'
              element={getNavWrappedComponent({
                component: <AssetDetails />,
                fullSize: true,
                rightSideBarAlwaysOpen: false,
                preventKeyPressDefault: true,
              })}></Route>

            <Route
              path='/drivers/:id'
              element={getNavWrappedComponent({
                component: <DriverProfile />,
                fullSize: true,
                rightSideBarAlwaysOpen: false,
                preventKeyPressDefault: true,
              })}></Route>

            <Route
              path='/reports'
              element={getNavWrappedComponent({
                component: <Reports />,
                pageTitle: 'Reports',
                titleMenu: (
                  <Link to='/reports/scheduler'>
                    <Button
                      startIcon={<ScheduleSend />}
                      color='secondary'
                      variant='outlined'>
                      {t('Report Scheduler')}
                    </Button>
                  </Link>
                ),
                rightSideBarAlwaysOpen: false,
              })}
            />

            <Route
              path='/reports'
              element={getNavWrappedComponent({
                component: <ReportDetails />,
                rightSideBarAlwaysOpen: false,
                hideHeader: true,
              })}>
              <Route path='scheduler' element={<ScheduledReports />} />
              <Route
                path='scheduler/create'
                element={<CrudScheduledReport />}
              />
              <Route
                path='scheduler/edit/:id'
                element={<CrudScheduledReport />}
              />
              <Route path='assets/trips/:id' element={<Trip />} />
              <Route
                path='vehicles/trips'
                element={<Trips assetType={'vehicle'} />}
              />
              <Route
                path='vehicles/status'
                element={<AssetsStatus assetType={'vehicle'} />}
              />
              <Route
                path='vessels_ais/trips'
                element={
                  <Trips assetType={'vessel_ais'} hideColumnIndexes={[2]} />
                }
              />
              <Route
                path='vessels_ais/status'
                element={<AssetsStatus assetType={'vessel_ais'} />}
              />
              <Route
                path='vessels/trips'
                element={<Trips assetType={'vessel'} hideColumnIndexes={[2]} />}
              />
              <Route
                path='trailers/trips'
                element={
                  <Trips assetType={'trailer'} hideColumnIndexes={[2]} />
                }
              />
              <Route
                path='containers/trips'
                element={
                  <Trips assetType={'container'} hideColumnIndexes={[2]} />
                }
              />
              <Route
                path='vessels/status'
                element={<AssetsStatus assetType={'vessel'} />}
              />
              <Route path='assets/logs' element={<AssetLog />} />
              <Route path='locations/activity' element={<LocationActivity />} />
              <Route path='assets/utilization' element={<AssetUtilization />} />
              <Route path='vehicles/fuel' element={<AssetFuel />} />
              <Route path='vehicles/speeding' element={<Speeding />} />
              <Route
                path='vehicles/local-speeding'
                element={<LocalSpeeding />}
              />
            </Route>

            <Route
              path='/settings'
              element={getNavWrappedComponent({
                component: <Settings />,
                pageTitle: 'Settings',
                rightSideBarAlwaysOpen: false,
              })}>
              <Route path='profile' element={<Profile />} />
              <Route path='assets' element={<Assets />} />
              <Route path='drivers' element={<Drivers />} />
              <Route path='driver-scorecard' element={<DriverBehavior />} />
              <Route path='groups' element={<Groups />} />
              <Route path='users' element={<Users />} />
              <Route path='locations' element={<Locations />} />
              <Route path='roles' element={<Roles />} />
              <Route path='integrations' element={<Integrations />} />
              <Route path='notifications' element={<NotificationsPage />} />
              <Route path='contacts' element={<Contacts />} />
              <Route path='ais-regions' element={<AISRegions />} />
              <Route path='billing' element={<Billing />} />
            </Route>

            <Route
              path='/driver-status'
              element={getNavWrappedComponent({
                component: <DriverReport boxed={false} />,
                pageTitle: 'Drivers',
                rightSideBarAlwaysOpen: false,
              })}
            />

            <Route
              path='/vehicle-status'
              element={getNavWrappedComponent({
                component: <AssetsStatus assetType={'vehicle'} boxed={false} />,
                pageTitle: 'Vehicles',
                rightSideBarAlwaysOpen: false,
              })}
            />

            <Route
              path='/trailer-status'
              element={getNavWrappedComponent({
                component: <AssetsStatus assetType={'trailer'} boxed={false} />,
                pageTitle: 'Trailers',
                rightSideBarAlwaysOpen: false,
              })}
            />

            <Route
              path='trailers/trips'
              element={<Trips assetType={'trailer'} hideColumnIndexes={[2]} />}
            />

            <Route
              path='/container-status'
              element={getNavWrappedComponent({
                component: (
                  <AssetsStatus assetType={'container'} boxed={false} />
                ),
                pageTitle: 'Containers',
                rightSideBarAlwaysOpen: false,
              })}
            />

            <Route
              path='containers/trips'
              element={
                <Trips assetType={'container'} hideColumnIndexes={[2]} />
              }
            />

            <Route
              path='/vessel-ais-status'
              element={getNavWrappedComponent({
                component: (
                  <AssetsStatus assetType={'vessel_ais'} boxed={false} />
                ),
                pageTitle: 'AIS Vessels',
                rightSideBarAlwaysOpen: false,
              })}
            />

            <Route
              path='/vessel-status'
              element={getNavWrappedComponent({
                component: <AssetsStatus assetType={'vessel'} boxed={false} />,
                pageTitle: 'Vessels',
                rightSideBarAlwaysOpen: false,
              })}
            />

            <Route
              path='*'
              element={getNavWrappedComponent({ component: <div>404</div> })}
            />
          </>
        )}
      </Routes>
    </Router>
  );
};

export default AppRouting;
