import WithUrlFilters from 'components/TableToolbar/WithUrlFilters';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import { MoreVertRounded } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import buffer from '@turf/buffer';
import center from '@turf/center';
import * as turf from '@turf/helpers';
import BoundingBoxes from 'assets/bounding-boxes.json';
import HeadingImagePanic from 'assets/heading-mini-panic.svg';
import HeadingImage from 'assets/heading-mini.svg';
import SecurityCameraFovImage from 'assets/security-camera-fov.svg';
import StaticAssetPipeMonitorImage from 'assets/static-asset-pipemonitor-mini.svg';
import StaticAssetWellheadImage from 'assets/static-asset-wellhead-mini.svg';
import VesselAisImage from 'assets/vessel-ais-mini.svg';
import VesselImage from 'assets/vessel-mini.svg';
import VesselNoHeadingNoSpeedPanic from 'assets/vessel-no-heading-no-speed-panic.svg';
import VesselNoHeadingNoSpeed from 'assets/vessel-no-heading-no-speed.svg';
import {
  convertUnitForAssetType,
  getDeviceFullName,
  getDirectionFromDeg,
  getUnitForAssetType,
} from 'common-web/utils';
import GaugeCluster from 'components/Assets/Details/GaugeCluster';
import DateFilter, { IntervalType } from 'components/Filters/DateFilter';
import DefaultDriverField from 'components/Forms/Fields/DefaultDriverField';
import {
  AssetLocationSource,
  DeviceHeading,
  DevicePoint,
  LAYERS,
  SOURCES,
  VesselLocation,
} from 'components/Map';
import Map from 'components/Map/AltMap';
import CameraFOV from 'components/Map/constants/layers/CameraFOV';
import CameraLocation from 'components/Map/constants/layers/CameraLocation';
import VesselAisLocation from 'components/Map/constants/layers/VesselAisLocation';
import StaticAssetSecurityCameraSource from 'components/Map/constants/sources/StaticAssetSecurityCameraSource';
import AssetStaticLineChart from 'components/Reports/Widgets/AssetStaticLineChart';
import ContainerLineChart from 'components/Reports/Widgets/ContainerLineChart';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import { Formik } from 'formik';
import {
  useAsset,
  useCurrentUser,
  useLocalStorage,
  useRequestAssetImage,
  useStaticAssetPipelines,
  useUpdateAssetDefaultDriver,
} from 'hooks';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import moment from 'moment';
import { ReactCountryFlag } from 'react-country-flag';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Location } from 'types';
import Alerts from 'views/Reports/Alerts';
import Trips from 'views/Reports/Trips';
import * as Yup from 'yup';
import PipelineLineAnimationLayer from '../../../../components/Map/constants/layers/PipelineLineAnimationLayer';
import PipelineLineLayer from '../../../../components/Map/constants/layers/PipelineLineLayer';
import PipelineMonitor from '../../../../components/Map/constants/layers/PipelineMonitor';
import WellheadLocation from '../../../../components/Map/constants/layers/WellheadLocation';
import OtherStaticAssetSource from '../../../../components/Map/constants/sources/OtherStaticAssetSource';
import PipelineSource from '../../../../components/Map/constants/sources/PipelineSource';
import CameraImageHistory from './CameraImageHistory';
import DropdownMenu from './DropdownMenu';

const getCountryISO2 = require('country-iso-3-to-2');

const assetPointLayer: any = DevicePoint({
  id: LAYERS.ASSET_POINTS,
  source: SOURCES.ASSET_LOCATIONS,
});
const assetHeadings: any = DeviceHeading({
  id: LAYERS.ASSET_HEADINGS,
  source: SOURCES.ASSET_LOCATIONS,
});
const vesselAisLocations: any = VesselAisLocation({
  id: LAYERS.VESSEL_LOCATIONS_AIS,
  source: SOURCES.ASSET_LOCATIONS,
});
const vesselLocations: any = VesselLocation({
  id: LAYERS.VESSEL_LOCATIONS,
  source: SOURCES.ASSET_LOCATIONS,
});
const staticAssetWellheadLocations: any = WellheadLocation({
  id: LAYERS.STATIC_ASSET_WELLHEAD_LOCATION,
  source: SOURCES.ASSET_LOCATIONS,
});
const staticAssetPipeMonitorLocations: any = PipelineMonitor({
  id: LAYERS.STATIC_ASSET_PIPEMONITOR_LOCATION,
  source: SOURCES.ASSET_LOCATIONS,
});
const pipelineLineLayer: any = PipelineLineLayer({
  id: LAYERS.STATIC_ASSET_PIPELINE_LINE_LAYER,
  source: SOURCES.STATIC_ASSET_PIPELINE_SOURCE,
});
const pipelineAnimationLineLayer: any = PipelineLineAnimationLayer({
  id: LAYERS.STATIC_ASSET_PIPELINE_LINE_ANIMATION_LAYER,
  source: SOURCES.STATIC_ASSET_PIPELINE_SOURCE,
});
const otherStaticAssetWellheadLocations: any = WellheadLocation({
  id: LAYERS.STATIC_ASSET_WELLHEAD_LOCATION,
  source: SOURCES.GENERIC_STATIC_ASSET_SOURCE,
  opacity: 0.6,
});
const otherStaticAssetPipeMonitorLocations: any = PipelineMonitor({
  id: LAYERS.STATIC_ASSET_PIPEMONITOR_LOCATION,
  source: SOURCES.GENERIC_STATIC_ASSET_SOURCE,
  opacity: 0.6,
});
const securityCameraLayer: any = CameraLocation({
  id: LAYERS.STATIC_ASSET_SECURITY_CAMERAS,
  source: SOURCES.STATIC_ASSET_CAMERA_LOCATIONS,
});
const securityCameraFovLayer: any = CameraFOV({
  id: LAYERS.STATIC_ASSET_SECURITY_CAMERAS_FOV,
  source: SOURCES.STATIC_ASSET_CAMERA_LOCATIONS,
});
const assetHeadingsImage: any = {
  id: 'heading',
  src: HeadingImage,
  type: 'svg',
};
const assetHeadingsImagePanic: any = {
  id: 'heading_panic',
  src: HeadingImagePanic,
  type: 'svg',
};
const vesselAisImage: any = {
  id: 'vessel_ais',
  src: VesselAisImage,
  type: 'svg',
};
const vesselImage: any = {
  id: 'vessel',
  src: VesselImage,
  type: 'svg',
};
const staticAssetWellheadImage: any = {
  id: 'static_asset_wellhead_image',
  src: StaticAssetWellheadImage,
  type: 'svg',
};
const staticAssetPipeMonitorImage: any = {
  id: 'static_asset_pipemonitor_image',
  src: StaticAssetPipeMonitorImage,
  type: 'svg',
};
const securityCameraFovImage: any = {
  id: 'security_camera_fov_image',
  src: SecurityCameraFovImage,
  type: 'svg',
};
const vesselNoHeadingNoSpeedImage: any = {
  id: 'vessel-no-heading-no-speed',
  src: VesselNoHeadingNoSpeed,
  type: 'svg',
};
const vesselNoHeadingNoSpeedPanicImage: any = {
  id: 'vessel-no-heading-no-speed-panic',
  src: VesselNoHeadingNoSpeedPanic,
  type: 'svg',
};

const DetailsListItem = ({ label, value }: any) => {
  return (
    <ListItem sx={{ py: 0.5 }}>
      <ListItemText>
        <Grid container>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: '.8rem' }}>{label}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography sx={{ fontSize: '.8rem', color: '#a1a1a1' }}>
              {value}
            </Typography>
          </Grid>
        </Grid>
      </ListItemText>
    </ListItem>
  );
};

const getImagesForAssetType = (assetType: string) => {
  if (['vehicle', 'trailer', 'container'].includes(assetType))
    return [assetHeadingsImage, assetHeadingsImagePanic];
  if ('vessel_ais' === assetType) return [vesselAisImage];
  if ('vessel' === assetType)
    return [
      vesselImage,
      vesselNoHeadingNoSpeedImage,
      vesselNoHeadingNoSpeedPanicImage,
    ];
  if (['wellhead', 'pipeline'].includes(assetType)) {
    return [staticAssetWellheadImage, staticAssetPipeMonitorImage];
  }
  if ('security_camera' === assetType) return [securityCameraFovImage];
  return [];
};

const getCountryBbox = (countryCode: string) => {
  const cc = getCountryISO2(countryCode);
  if (!cc) return null;

  // @ts-ignore
  const row = BoundingBoxes[cc];
  if (!row) return null;

  const bboxRaw = row[1];
  if (!bboxRaw) return null;

  return center(bboxPolygon(bboxRaw));
};

const AssetDetails = () => {
  const mapRef = React.useRef<any>();
  const currentStep = React.useRef<number>(0);
  const animating = React.useRef<any>(null);
  const { setFlashMessage, isMobile } = useUIContext();
  const { t } = useTranslation();
  let { id: assetId } = useParams() as any;
  const [locationBbox, setLocationBbox] = React.useState<any>(null);
  const { data: currentUser } = useCurrentUser() as any;
  const [mapCenter, setCenter] = React.useState<any>(null);
  const [assetLocationsDataSource, setAssetLocationsDataSource] =
    React.useState<any>();
  const [pipelineDataSource, setPipelineDataSource] = React.useState<any>();
  const [otherStaticAssetSource, setOtherStaticAssetSource] =
    React.useState<any>();
  const [securityCameraSource, setSecurityCameraSource] = React.useState<any>();
  const { data: assetDetails } = useAsset({ id: assetId }) as any;
  const [storedMapStyle, _] = useLocalStorage('mapStyle', '');
  const navigate = useNavigate();
  const [hasMedia, setHasMedia] = React.useState<'vehicle' | 'camera' | null>(
    null
  );
  const [isStaticAsset, setIsStaticAsset] = React.useState<boolean>(false);
  const [isTrailerContainer, setIsTrailerContainer] =
    React.useState<boolean>(false);
  const [currentMediaIndex, setCurrentMediaIndex] = React.useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [mediaMouseOver, setMediaMouseOver] = React.useState<boolean>(false);
  const {
    mutate: requestImage,
    isSuccess: requestImageSuccess,
    isError,
  } = useRequestAssetImage();
  const [searchParams]: any = useComplexSearchParams();
  const assetIdIfStatic = React.useMemo(() => {
    if (!assetDetails) return null;
    if (!['wellhead', 'pipeline'].includes(assetDetails?.assetType))
      return null;

    return assetDetails?.assetId;
  }, [assetDetails]);
  const { data: pipelineData } = useStaticAssetPipelines({
    assetId: assetIdIfStatic,
  }) as any;

  const getSourcesForAssetType = (assetType: string) => {
    if (['wellhead', 'pipeline'].includes(assetType)) {
      return [
        pipelineDataSource,
        assetLocationsDataSource,
        otherStaticAssetSource,
      ];
    }
    if ('security_camera' === assetType) {
      return [securityCameraSource];
    }
    return [assetLocationsDataSource];
  };

  const getLayersForAssetType = (assetType: string) => {
    if (mapCenter[0] === 0 && mapCenter[1] === 0) return [];
    if (['vehicle', 'container', 'trailer'].includes(assetType))
      return [assetPointLayer, assetHeadings];
    if ('vessel_ais' === assetType) return [vesselAisLocations];
    if ('vessel' === assetType) return [vesselLocations];
    if ('wellhead' === assetType)
      return [
        otherStaticAssetWellheadLocations,
        otherStaticAssetPipeMonitorLocations,
        staticAssetWellheadLocations,
        pipelineLineLayer,
        pipelineAnimationLineLayer,
      ];
    if ('pipeline' === assetType)
      return [
        otherStaticAssetWellheadLocations,
        otherStaticAssetPipeMonitorLocations,
        staticAssetPipeMonitorLocations,
        pipelineLineLayer,
        pipelineAnimationLineLayer,
      ];
    if ('security_camera' === assetType) {
      return [securityCameraLayer, securityCameraFovLayer];
    }
  };

  const [showAllTrips, setShowAllTrips] = React.useState<boolean>(false);
  const handleShowAllTrips = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAllTrips(event.target.checked);
  };

  React.useEffect(() => {
    if (
      assetDetails?.assetRecent?.position?.lon &&
      assetDetails?.assetRecent?.position?.lat
    ) {
      setCenter([
        assetDetails?.assetRecent?.position?.lon,
        assetDetails?.assetRecent?.position?.lat,
      ]);
      return;
    }

    if (currentUser?.settings?.countryCode) {
      const bbox = getCountryBbox(currentUser.settings.countryCode);
      if (bbox?.geometry?.coordinates) {
        setCenter(bbox.geometry.coordinates);
        return;
      }
    }

    setCenter([0, 0]);
  }, [assetDetails, currentUser]);

  React.useEffect(() => {
    if (!pipelineData || pipelineData.length === 0) return;
    if (!isStaticAsset) return;

    let sourceData: any[] = [];
    let assetData: any[] = [];
    let pipelinePoints: any[] = [];
    pipelineData.forEach((item: any) => {
      if (!item || !item.nodes || item.nodes.length === 0) return;
      item.nodes.map((node: any) => {
        sourceData.push({
          ...node,
          pipelineId: item.pipelineId,
        });
        if (node.assetFrom) {
          assetData.push(node.assetFrom);
          pipelinePoints.push([
            node.assetFrom?.position.lon,
            node.assetFrom?.position.lat,
          ]);
        }
        if (node.assetTo) {
          assetData.push(node.assetTo);
          pipelinePoints.push([
            node.assetTo?.position.lon,
            node.assetTo?.position.lat,
          ]);
        }
      });
    });

    setPipelineDataSource(PipelineSource(sourceData));

    setOtherStaticAssetSource(OtherStaticAssetSource(assetData));

    const lineString = turf.lineString([...pipelinePoints]);

    const locationBbox = bboxPolygon(
      bbox(buffer(lineString.geometry, 4, { units: 'kilometers' }))
    );
    setLocationBbox(locationBbox);
    setCenter(center(locationBbox));
  }, [pipelineData, isStaticAsset]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!!requestImageSuccess) {
      setFlashMessage({ message: `Image Requested from Asset` });
      setAnchorEl(null);
    }
  }, [requestImageSuccess]);

  useEffect(() => {
    if (!!isError) {
      setFlashMessage({
        message: `Please wait some time before requesting a new image.`,
        severity: 'error',
      });
      setAnchorEl(null);
    }
  }, [isError]);

  const getBboxBufferByAssetType = (assetType: string): number => {
    if (['vessel_ais', 'vessel'].includes(assetDetails?.assetType)) {
      return 1_000;
    }
    return 2;
  };

  useEffect(() => {
    if (!assetDetails) return;
    if (!assetDetails.assetRecent?.position?.lon) return;
    if (!assetDetails.assetRecent?.position?.lat) return;
    const locationPoint = turf.point([
      parseFloat(assetDetails.assetRecent?.position.lon),
      parseFloat(assetDetails.assetRecent?.position.lat),
    ]);
    const locationBbox = bboxPolygon(
      bbox(
        buffer(
          locationPoint.geometry,
          getBboxBufferByAssetType(assetDetails?.assetType),
          { units: 'kilometers' }
        )
      )
    );
    const isStaticAsset = ['wellhead', 'pipeline'].includes(
      assetDetails.assetType
    );
    if (!isStaticAsset) {
      setLocationBbox(locationBbox);
      setCenter(center(locationBbox));
    }
    const hasVehicleMedia =
      assetDetails.medias && assetDetails.medias?.length > 0;
    const hasCameraMedia =
      assetDetails.assetMedia && assetDetails.assetMedia?.length > 0;
    setHasMedia(hasVehicleMedia ? 'vehicle' : hasCameraMedia ? 'camera' : null);
    setIsStaticAsset(isStaticAsset);
    setIsTrailerContainer(
      ['container', 'trailer'].includes(assetDetails.assetType)
    );
  }, [assetDetails]);

  React.useEffect(() => {
    if (assetDetails) {
      if ('security_camera' === assetDetails?.assetType) {
        setSecurityCameraSource(
          StaticAssetSecurityCameraSource([
            {
              ...assetDetails,
              ...assetDetails.assetRecent,
            },
          ] as Location[])
        );
      } else {
        setAssetLocationsDataSource(
          AssetLocationSource([
            {
              ...assetDetails,
              ...assetDetails.assetRecent,
            },
          ] as Location[])
        );
      }
    }
  }, [assetDetails]);

  const flipMedia = async () => {
    setCurrentMediaIndex((currentMediaIndex + 1) % assetDetails.medias?.length);
  };

  const dashArraySequence = [
    [0, 4, 3],
    [0.5, 4, 2.5],
    [1, 4, 2],
    [1.5, 4, 1.5],
    [2, 4, 1],
    [2.5, 4, 0.5],
    [3, 4, 0],
    [0, 0.5, 3, 3.5],
    [0, 1, 3, 3],
    [0, 1.5, 3, 2.5],
    [0, 2, 3, 2],
    [0, 2.5, 3, 1.5],
    [0, 3, 3, 1],
    [0, 3.5, 3, 0.5],
  ];

  const animateProperty = (timestamp: any) => {
    if (!mapRef.current) return;
    if (!mapRef.current.getLayer) return;
    if (!mapRef.current.setPaintProperty) return;

    animating.current = true;

    const newStep = Math.floor((timestamp / 50) % dashArraySequence.length);

    if (
      newStep !== currentStep.current &&
      mapRef.current?.getLayer('static-asset-pipeline-line-animation-layer')
    ) {
      mapRef.current?.setPaintProperty(
        'static-asset-pipeline-line-animation-layer',
        'line-dasharray',
        dashArraySequence[newStep]
      );
      currentStep.current = newStep;
    }

    requestAnimationFrame(animateProperty);
  };

  if (!animating.current) {
    animateProperty(0);
  }

  const fullMap = () => {
    return !!hasMedia || isStaticAsset || isTrailerContainer;
  };

  return (
    <Box sx={{ overflow: 'scroll', height: '100vh' }}>
      <Box
        sx={{
          width: isMobile ? '92vw' : '80vw',
          minHeight: '100vh',
          m: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          textAlign: 'left',
          justifyContent: 'top',
          ...(isMobile && {
            paddingTop: '60px',
            paddingBottom: '200px',
          }),
        }}>
        <Box sx={{ mt: 1 }}>
          <Box sx={{}}>
            <Button
              size={'small'}
              sx={{ textTransform: 'capitalize' }}
              startIcon={<ArrowBackIcon sx={{ height: '.9em' }} />}
              onClick={() => navigate(-1)}>
              Back
            </Button>
          </Box>
        </Box>

        {!assetDetails && (
          <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid item xs={1}>
              <Skeleton variant='text' width={'100%'} height={'4rem'} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant='rounded' width={'100%'} height={'15rem'} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant='rounded' width={'100%'} height={'15rem'} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant='rounded' width={'100%'} height={'15rem'} />
            </Grid>
          </Grid>
        )}

        {assetDetails && (
          <>
            <Typography
              sx={{
                display: 'flex',
                position: 'static',
                backgroundColor: 'transparent',
                width: '100%',
                marginBottom: 0,
                pt: '.4em',
                pb: '.4em',
                zIndex: 99,
                fontSize: '2em',
                textAlign: 'left',
              }}>
              {assetDetails?.name}
              {isStaticAsset && (
                <Box sx={{ width: '15rem', ml: 'auto', mr: 0 }}>
                  <WithUrlFilters>
                    <DateFilter
                      sx={{ mt: 0.5, mb: 1 }}
                      dateOptions={[
                        IntervalType.TWENTYFOURHOURS,
                        IntervalType.LAST_THIRTY_DAYS,
                      ]}
                    />
                  </WithUrlFilters>
                </Box>
              )}
              {!!hasMedia && (
                <>
                  <IconButton onClick={handleClick}>
                    <MoreVertRounded />
                  </IconButton>
                  <Popover
                    id={Boolean(anchorEl) ? 'simple-popover' : undefined}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}>
                    <Typography sx={{ p: 1 }}>
                      <Button
                        onClick={() =>
                          requestImage({ assetId: assetDetails?.assetId })
                        }>
                        Request Image
                      </Button>
                    </Typography>
                  </Popover>
                </>
              )}
            </Typography>
            <Box
              sx={{
                position: 'relative',
                justifyContent: 'center',
              }}>
              <Grid
                container
                spacing={2}
                sx={{ height: fullMap() ? '100%' : '18rem' }}>
                <Grid
                  item
                  xs={12}
                  md={
                    hasMedia
                      ? 5
                      : isTrailerContainer
                      ? 6
                      : isStaticAsset
                      ? 8
                      : 12
                  }>
                  {assetDetails && mapCenter && (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '7px',
                        overflow: 'hidden',
                      }}>
                      <Map
                        mapRef={mapRef}
                        hasControls={false}
                        hasDraw={false}
                        layers={
                          !!assetDetails?.assetRecent?.timestamp
                            ? getLayersForAssetType(assetDetails?.assetType)
                            : []
                        }
                        images={getImagesForAssetType(assetDetails?.assetType)}
                        sources={getSourcesForAssetType(
                          assetDetails?.assetType
                        )}
                        bounds={locationBbox?.bbox}
                        center={!locationBbox ? mapCenter : undefined}
                        zoom={1}
                        minZoom={0}
                        dragPan={false}
                        mapStyle={storedMapStyle}
                      />
                      <DropdownMenu
                        assetId={assetDetails?.assetId}
                        assetName={assetDetails?.name}
                        hasVirtualOdometer={assetDetails?.hasVirtualOdometer}
                        ignitionLockout={{
                          hasIgnitionLockout: assetDetails?.hasIgnitionLockout,
                          lastRequestedState:
                            assetDetails?.ignitionLockoutRequestedState,
                          lastRequestedAt:
                            assetDetails?.ignitionLockoutRequestedAt,
                          currentState:
                            assetDetails?.assetRecent?.stateIgnitionLockedOut,
                        }}
                      />
                    </Box>
                  )}
                </Grid>
                {isStaticAsset && (
                  <Grid item xs={12} md={8}>
                    <Box
                      sx={{
                        aspectRatio: '4 / 1.2',
                        background: 'rgba(0, 0, 0, 0.25)',
                        borderRadius: '7px',
                        py: 1,
                        px: 1,
                      }}>
                      <AssetStaticLineChart
                        assetType='wellhead'
                        widgetType='single_asset'
                        assetId={assetDetails.assetId}
                        chartUnit='psi'
                        showTotal={false}
                        startTimestamp={searchParams?.startTimestamp}
                        endTimestamp={searchParams?.endTimestamp}
                        interval={
                          searchParams?.dateRange ===
                          IntervalType.TWENTYFOURHOURS
                            ? 3
                            : 100
                        }
                      />
                    </Box>
                  </Grid>
                )}
                {isTrailerContainer && (
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        aspectRatio: '4 / 1.6',
                        background: 'rgba(0, 0, 0, 0.25)',
                        borderRadius: '7px',
                        py: 1,
                        px: 1,
                      }}>
                      <ContainerLineChart
                        assetType={assetDetails.assetType}
                        widgetType='temperature'
                        assetId={assetDetails.assetId}
                        chartUnit='C'
                        showTotal={false}
                        startTimestamp={moment()
                          .subtract(1, 'day')
                          .startOf('day')
                          .toDate()}
                        endTimestamp={moment().endOf('day').toDate()}
                        interval={
                          searchParams?.dateRange ===
                          IntervalType.TWENTYFOURHOURS
                            ? 3
                            : 100
                        }
                      />
                    </Box>
                  </Grid>
                )}
                {!!hasMedia && (
                  <Grid item xs={12} md={5}>
                    <Box
                      onMouseEnter={() => setMediaMouseOver(true)}
                      onMouseLeave={() => setMediaMouseOver(false)}
                      sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '7px',
                        overflow: 'hidden',
                        maxHeight: '100%',
                        aspectRatio: '4 / 2',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                      }}>
                      {'vehicle' === hasMedia && (
                        <Typography
                          sx={{
                            position: 'absolute',
                            background: 'rgba(0, 0, 0, 0.85)',
                            left: 0,
                            bottom: 0,
                            px: 1,
                            py: 0.3,
                          }}>
                          <Tooltip
                            arrow={true}
                            placement={'right'}
                            title={moment(
                              assetDetails.medias[0].timestamp
                            ).format('YYYY-MM-DD HH:mm:ss')}>
                            <Typography sx={{ fontSize: '.75rem' }}>
                              {moment(
                                assetDetails.medias[currentMediaIndex].timestamp
                              ).fromNow()}
                            </Typography>
                          </Tooltip>
                        </Typography>
                      )}
                      {'camera' === hasMedia && (
                        <Typography
                          sx={{
                            position: 'absolute',
                            background: 'rgba(0, 0, 0, 0.85)',
                            left: 0,
                            bottom: 0,
                            px: 1,
                            py: 0.3,
                          }}>
                          <Tooltip
                            arrow={true}
                            placement={'right'}
                            title={moment(
                              assetDetails.assetMedia[0].timestamp
                            ).format('YYYY-MM-DD HH:mm:ss')}>
                            <Typography sx={{ fontSize: '.75rem' }}>
                              {moment(
                                assetDetails.assetMedia[0].timestamp
                              ).fromNow()}
                            </Typography>
                          </Tooltip>
                        </Typography>
                      )}
                      {assetDetails.medias?.length > 0 && (
                        <>
                          {mediaMouseOver && (
                            <Button
                              sx={{
                                position: 'absolute',
                                width: 120,
                                height: 60,
                                right: 20,
                                bottom: 15,
                                p: 0,
                                borderRadius: '10px',
                                border: '2px solid white',
                                boxShadow: '0 0 10px rgba(0,0,0,0.5)',
                              }}
                              aria-label='delete'
                              onClick={() => flipMedia()}>
                              <img
                                src={
                                  0 === currentMediaIndex
                                    ? assetDetails.medias[1].fileUrl
                                    : assetDetails.medias[0].fileUrl
                                }
                                alt=''
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  borderRadius: '10px',
                                }}
                              />
                            </Button>
                          )}
                          <img
                            src={assetDetails.medias[currentMediaIndex].fileUrl}
                            alt=''
                            style={{
                              maxWidth: '100%',
                              minWidth: '100%',
                              maxHeight: 'auto',
                              flexShrink: 0,
                            }}
                          />
                        </>
                      )}
                      {assetDetails.assetMedia?.length > 0 && (
                        <div className='minfarm-watermarked'>
                          <img
                            src={assetDetails.assetMedia[0].fileUrl}
                            alt=''
                            style={{
                              maxWidth: '100%',
                              minWidth: '100%',
                              maxHeight: 'auto',
                              flexShrink: 0,
                            }}
                          />
                        </div>
                      )}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <GaugeCluster asset={assetDetails} />
                  <List
                    sx={{
                      background: 'rgba(0, 0, 0, 0.25)',
                      borderRadius: '7px',
                    }}>
                    {!!assetDetails?.assetRecent?.ignitionState &&
                      !!assetDetails.driver && (
                        <DetailsListItem
                          label={'Driver'}
                          value={
                            <Link
                              className='hoverable-link'
                              to={`/drivers/${assetDetails.driver?.driverUuid}`}>
                              {assetDetails.driver
                                ? `${assetDetails.driver?.firstname} ${assetDetails.driver?.lastname}`
                                : `Unnamed driver`}
                            </Link>
                          }
                        />
                      )}
                    {null !==
                      assetDetails?.assetDevice?.assetDeviceIdentifier &&
                      assetDetails?.assetDevice?.assetDeviceIdentifier?.map(
                        (identifier: any) => (
                          <DetailsListItem
                            key={`${identifier.type}_${identifier.identifier}`}
                            label={t(identifier.type)}
                            value={identifier.identifier}
                          />
                        )
                      )}
                    {null !== assetDetails?.assetDevice?.deviceType && (
                      <DetailsListItem
                        label={'Device'}
                        value={getDeviceFullName(
                          assetDetails?.assetDevice?.deviceType
                        )}
                      />
                    )}
                    {null !== assetDetails?.assetDevice?.softwareVersion && (
                      <DetailsListItem
                        label={'Software Version'}
                        value={assetDetails?.assetDevice?.softwareVersion}
                      />
                    )}
                    {'security_camera' !== assetDetails?.assetType && (
                      <DetailsListItem
                        label={'Last reported'}
                        value={
                          null !== assetDetails?.assetRecent?.timestamp ? (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                              }}>
                              <Tooltip
                                arrow={true}
                                title={moment(
                                  assetDetails?.assetRecent?.timestamp
                                ).format('YYYY-MM-DD HH:mm:ss')}>
                                <div style={{ width: 'fit-content' }}>
                                  {moment(
                                    assetDetails?.assetRecent?.timestamp
                                  ).fromNow(true)}{' '}
                                  ago
                                </div>
                              </Tooltip>
                            </Box>
                          ) : (
                            <Typography
                              sx={{
                                fontSize: '.8rem',
                              }}>
                              {t('Never reported')}
                            </Typography>
                          )
                        }
                      />
                    )}
                    {null !== assetDetails?.assetRecent?.position && (
                      <DetailsListItem
                        label={'Location'}
                        value={
                          <>
                            {assetDetails?.assetRecent?.position &&
                            assetDetails?.assetRecent?.position.lat &&
                            assetDetails?.assetRecent?.position.lon ? (
                              <Typography
                                sx={{
                                  fontSize: '.8rem',
                                }}>
                                {`
                                ${assetDetails?.assetRecent?.position?.lat?.toFixed(
                                  5
                                )},
                                ${assetDetails?.assetRecent?.position?.lon?.toFixed(
                                  5
                                )}
                              `}
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: '.8rem',
                                }}>
                                {t('Never reported')}
                              </Typography>
                            )}
                            <Typography sx={{ fontSize: '.8rem' }}>
                              <ReactCountryFlag
                                style={{ marginRight: '5px' }}
                                countryCode={getCountryISO2(
                                  assetDetails?.assetRecent?.countryCode
                                )}
                              />{' '}
                              {assetDetails?.assetRecent?.formattedAddress}
                            </Typography>
                          </>
                        }
                      />
                    )}
                    {null !== assetDetails.assetRecent?.speed && (
                      <DetailsListItem
                        label={'Speed'}
                        value={`${convertUnitForAssetType(
                          'speed',
                          assetDetails?.assetType,
                          assetDetails?.assetRecent?.speed
                        )} ${getUnitForAssetType(
                          'speed',
                          assetDetails?.assetType
                        )}`}
                      />
                    )}
                    {null !== assetDetails?.assetRecent?.heading && (
                      <DetailsListItem
                        label={'Heading'}
                        value={`${
                          assetDetails?.assetRecent?.heading || 0
                        }° (${getDirectionFromDeg(
                          assetDetails?.assetRecent?.heading || 0
                        )})`}
                      />
                    )}
                    {null !== assetDetails.group && (
                      <DetailsListItem
                        label={'Group'}
                        value={assetDetails.group?.name}
                      />
                    )}
                    {null !== assetDetails?.assetDetails &&
                      assetDetails?.assetDetails?.upstreamAssets &&
                      assetDetails?.assetDetails?.upstreamAssets.length > 0 && (
                        <DetailsListItem
                          label={'Upstream assets'}
                          value={assetDetails.assetDetails?.upstreamAssets.map(
                            (upstreamAsset: any) => (
                              <Link
                                key={upstreamAsset.assetId}
                                onClick={e => {
                                  e.preventDefault();
                                  navigate(`/assets/${upstreamAsset.assetId}`);
                                }}
                                to={`/assets/${upstreamAsset.assetId}`}
                                className='hoverable-link'>
                                {upstreamAsset.name}
                              </Link>
                            )
                          )}
                        />
                      )}
                    {null !== assetDetails?.assetDetails &&
                      assetDetails?.assetDetails?.downstreamAssets &&
                      assetDetails?.assetDetails?.downstreamAssets.length >
                        0 && (
                        <DetailsListItem
                          label={'Downstream assets'}
                          /* eslint-disable-next-line max-len */
                          value={assetDetails.assetDetails?.downstreamAssets.map(
                            (downstreamAsset: any) => (
                              <Link
                                key={downstreamAsset.assetId}
                                onClick={e => {
                                  e.preventDefault();
                                  navigate(
                                    `/assets/${downstreamAsset.assetId}`
                                  );
                                }}
                                to={`/assets/${downstreamAsset.assetId}`}
                                className='hoverable-link'>
                                {downstreamAsset.name}
                              </Link>
                            )
                          )}
                        />
                      )}
                    {assetDetails.defaultDriver && (
                      <DefaultDriverDetails assetDetails={assetDetails} />
                    )}
                    {assetDetails.internalTemp && (
                      <DetailsListItem
                        label={'Internal Temperature'}
                        value={`${assetDetails.internalTemp} c`}
                      />
                    )}
                    {assetDetails.assetDetails.containerSize && (
                      <DetailsListItem
                        label={'Size'}
                        value={t(
                          `asset.container.size.${assetDetails.assetDetails.containerSize}`
                        )}
                      />
                    )}
                    {assetDetails.assetDetails.trailerLength && (
                      <DetailsListItem
                        label={'Length'}
                        value={`${assetDetails.assetDetails.trailerLength} ft`}
                      />
                    )}
                    {assetDetails.assetDetails.trailerType && (
                      <DetailsListItem
                        label={'Type'}
                        value={t(
                          `asset.trailer.size.${assetDetails.assetDetails.trailerType}`
                        )}
                      />
                    )}
                    {assetDetails.assetDetails.trailerWeightCapacity && (
                      <DetailsListItem
                        label={'Weight'}
                        value={`${assetDetails.assetDetails.trailerWeightCapacity} mt`}
                      />
                    )}
                  </List>
                </Grid>
                {!isStaticAsset &&
                  'security_camera' !== assetDetails?.assetType && (
                    <Grid item xs={12} md={8}>
                      <Box
                        sx={{
                          p: 2,
                          background: 'rgba(0, 0, 0, 0.25)',
                          borderRadius: '7px',
                        }}>
                        {'vessel_ais' === assetDetails?.assetType ? (
                          <Typography sx={{ pb: 2 }}>
                            This Months Trips
                          </Typography>
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}>
                            <Typography sx={{ pb: 2 }}>
                              This Weeks Trips
                            </Typography>
                            <Tooltip
                              title={t('reports.show_all_trips_tooltip')}
                              arrow
                              placement='bottom'>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={showAllTrips}
                                      size='small'
                                      color='secondary'
                                      onChange={handleShowAllTrips}
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{ fontSize: '.8rem', ml: 1 }}>
                                      {t('reports.show_all_trips')}
                                    </Typography>
                                  }
                                />
                              </FormGroup>
                            </Tooltip>
                          </Box>
                        )}
                        <Trips
                          assetType={assetDetails?.assetType}
                          showFilters={false}
                          hideColumnIndexes={[0, 2]}
                          filterInterval={
                            'vessel_ais' === assetDetails?.assetType ||
                            'vessel' === assetDetails?.assetType
                              ? IntervalType.LAST_THIRTY_DAYS
                              : IntervalType.TWENTYFOURHOURS
                          }
                          saveFiltersInQueryString={false}
                          assetIds={[assetDetails.assetId]}
                          boxed={false}
                          pageSize={6}
                          showAllTrips={showAllTrips}
                        />
                      </Box>
                    </Grid>
                  )}
                {isStaticAsset && (
                  <Grid item xs={12} md={8}>
                    <Box
                      sx={{
                        p: 2,
                        background: 'rgba(0, 0, 0, 0.25)',
                        borderRadius: '7px',
                      }}>
                      <Typography sx={{ pb: 2 }}>Recent Alerts</Typography>

                      <Alerts
                        assetId={assetDetails?.assetId}
                        showFilters={false}
                        hideColumnIndexes={[0, 2]}
                        filterInterval={
                          'vessel_ais' === assetDetails?.assetType ||
                          'vessel' === assetDetails?.assetType
                            ? IntervalType.LAST_THIRTY_DAYS
                            : IntervalType.TWENTYFOURHOURS
                        }
                        saveFiltersInQueryString={false}
                        assetIds={[assetDetails.assetId]}
                        boxed={false}
                        pageSize={5}
                      />
                    </Box>
                  </Grid>
                )}
                {'security_camera' === assetDetails?.assetType && (
                  <Grid item xs={12} md={8}>
                    <Box
                      sx={{
                        p: 2,
                        background: 'rgba(0, 0, 0, 0.25)',
                        borderRadius: '7px',
                      }}>
                      <Typography sx={{ pb: 2 }}>Recent Images</Typography>
                      <CameraImageHistory assetId={assetDetails?.assetId} />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

const DefaultDriverDetails = ({ assetDetails }: any) => {
  const navigate = useNavigate();
  const [editDriverMode, setEditDriverMode] = React.useState<boolean>(false);
  const { canWrite } = usePermissions();
  const formRef = React.useRef<any>(null);
  const {
    mutate: updateAssetDefaultDriver,
    error: updateAssetError,
    isSuccess: updateAssetSuccess,
  } = useUpdateAssetDefaultDriver();
  const { t } = useTranslation();
  const { setFlashMessage } = useUIContext();

  const handleChangeDriver = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
      updateAssetDefaultDriver({
        assetId: assetDetails.assetId,
        assetUuid: assetDetails.assetUuid,
        ...formRef.current.values,
      });
    }
  };

  useEffect(() => {
    if (updateAssetSuccess) {
      setEditDriverMode(false);
      setFlashMessage({ message: t('success.edit_asset') });
    }
  }, [updateAssetSuccess]);

  useEffect(() => {
    if (updateAssetError) {
      setEditDriverMode(false);
      setFlashMessage({ message: t('error'), severity: 'error' });
    }
  }, [updateAssetError]);

  return (
    <DetailsListItem
      label={'Default Driver'}
      value={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          {!editDriverMode && (
            <Chip
              size='small'
              onClick={() =>
                navigate(`/drivers/${assetDetails.defaultDriver?.driverUuid}`)
              }
              label={
                assetDetails.defaultDriver
                  ? `${assetDetails.defaultDriver?.firstName} ${assetDetails.defaultDriver?.lastName}`
                  : `Unnamed driver`
              }
              variant='outlined'
            />
          )}
          {editDriverMode && (
            <Formik
              innerRef={formRef}
              enableReinitialize={true}
              initialValues={{
                defaultDriver: assetDetails.defaultDriver
                  ? {
                      id: assetDetails.defaultDriver?.driverId,
                      value: `${assetDetails.defaultDriver?.firstName} ${assetDetails.defaultDriver?.lastName}`,
                    }
                  : {},
              }}
              validationSchema={Yup.object().shape({
                defaultDriver: Yup.object().required(),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
              }}>
              <DefaultDriverField
                sx={{ width: '100%', mr: 1 }}
                size='small'
                field={{
                  field: 'defaultDriver',
                  placeHolder: 'Select Driver',
                }}
              />
            </Formik>
          )}
          {canWrite('DRIVER') && editDriverMode && (
            <>
              <Tooltip title='Cancel'>
                <IconButton
                  onClick={() => setEditDriverMode(false)}
                  sx={{ p: 0, mx: 1 }}>
                  <CancelIcon sx={{ fontSize: '1.2rem' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Save'>
                <IconButton onClick={handleChangeDriver} sx={{ p: 0 }}>
                  <CheckCircleIcon sx={{ fontSize: '1.2rem' }} />
                </IconButton>
              </Tooltip>
            </>
          )}
          {canWrite('DRIVER') && !editDriverMode && (
            <Tooltip title='Change default driver'>
              <IconButton onClick={() => setEditDriverMode(true)}>
                <ModeEditIcon sx={{ fontSize: '1rem' }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      }
    />
  );
};

export default AssetDetails;
