import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import mapboxgl, { LngLatLike } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect } from 'react';
// @ts-ignore - no types
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
// import MapboxLanguage from '@mapbox/mapbox-gl-language';
import DrawLayerStyle from './constants/layers/DrawLayerStyle';
import './map.scss';
/* @ts-ignore */
import { interpolateNumberArray } from 'd3-interpolate';
import useKeyPress from 'hooks/useKeyPress';
/* @ts-ignore */
import { DIR } from 'context/UIContext';
/* @ts-ignore */
import FreehandMode from 'mapbox-gl-draw-freehand-mode';
import usePermissions from '../../context/PermissionContext';
import { Source } from './constants';
import DrawDisabledMode from './modes/draw_disabled';
import DrawPipelineMode from './modes/draw_pipeline';
// let customMapControl: IControl;

const ScaleControl = new mapboxgl.ScaleControl({
  maxWidth: 80,
  unit: 'metric',
});

const deltaDistance = 50;

function easing(t: number) {
  return t * (2 - t);
}

const POPUP_HIDDEN_CLASS = 'popup-hidden';
const POPUP_DEFAULT_CLASS = 'width-360px';
const POPUP_DEFAULT_CLASS_HOVER = 'width-auto';
const POPUP_ANIMATION_DURATION = 200;

type CustomAnyLayer = {
  source: string;
};
type AnyLayer = mapboxgl.AnyLayer & CustomAnyLayer;

interface IProps {
  center?: LngLatLike | any;
  mapStyle?: string;
  layers?: AnyLayer[] | null;
  clickableLayers?: any;
  hoverableLayers?: any;
  sources?: Source[] | null;
  images?: any[];
  width?: string;
  height?: string;
  onClick?: any;
  onClickAnywhere?: any;
  onHover?: any;
  onHoverExit?: any;
  onRightClick?: any;
  zoom?: number;
  onLoad?: Function;
  onDraw?: Function;
  bounds?: any;
  initialBounds?: any;
  maxBounds?: any;
  drawControl?: boolean;
  popup?: any;
  style?: any;
  controlStyles?: any;
  drawMode?: string | null;
  drawModeExtra?: any;
  drawCustomFeatures?: any;
  drawCustomLayerStyle?: any;
  onDrawModeEntered?: Function;
  onDrawModeExited?: Function;
  onDrawModeChanged?: Function;
  resetDrawnFeatures?: boolean;
  onCenterChanged?: Function;
  onZoomChanged?: Function;
  onPopupClose?: Function;
  onPopupOpen?: Function;
  flyTo?: LngLatLike;
  onStyleChange?: Function;
  hasControls?: boolean;
  hasDraw?: boolean;
  projection?: any;
  customContainerClass?: string;
  contextMenu?: any;
  minZoom?: number;
  maxZoom?: number;
  setPopup?: Function;
  dragPan?: boolean;
  deleteFeatureById?: string | null;
  show3D?: boolean;
  onUpdateSource?: Function;
  onResetSource?: Function;
  mapRef?: any;
  direction?: 'rtl' | 'ltr';
  lang?: string;
  CustomControls?: any;
  keyboard?: boolean;
  googleAttributionStyle?: any;
}

function AltMap({
  center,
  mapStyle,
  width = '100%',
  height = '100%',
  layers,
  clickableLayers,
  hoverableLayers,
  sources,
  images,
  onClick,
  onClickAnywhere,
  onHover,
  onHoverExit,
  onRightClick,
  zoom = 9,
  onLoad,
  onDraw,
  bounds,
  initialBounds,
  maxBounds,
  drawControl = false, // TODO: implement this or clean it up
  popup,
  style,
  controlStyles,
  drawMode,
  drawModeExtra,
  drawCustomLayerStyle,
  drawCustomFeatures,
  onDrawModeEntered, // TODO: implement this
  onDrawModeExited, // TODO: implement this
  onDrawModeChanged,
  resetDrawnFeatures = false,
  onCenterChanged,
  onZoomChanged,
  onPopupClose,
  onPopupOpen,
  flyTo,
  onStyleChange,
  hasControls = true,
  hasDraw = true,
  projection = 'mercator',
  customContainerClass = '',
  contextMenu,
  minZoom = 2.0,
  maxZoom = 17.5,
  setPopup,
  dragPan = true,
  deleteFeatureById = null,
  show3D = false,
  onUpdateSource,
  onResetSource,
  mapRef = null,
  direction = 'ltr',
  lang,
  CustomControls,
  keyboard = true,
  googleAttributionStyle,
}: IProps) {
  const Draw = React.useRef<MapboxDraw | null>(null);
  if (!Draw.current) {
    Draw.current = new MapboxDraw({
      defaultMode: 'simple_select',
      // @ts-ignore
      modes: Object.assign(
        {
          draw_freehand: FreehandMode,
          draw_disabled: DrawDisabledMode,
          draw_pipeline: DrawPipelineMode,
        },
        MapboxDraw.modes
      ),
      userProperties: true,
      styles: drawCustomLayerStyle || DrawLayerStyle,
    });
  }

  const mapContainer = React.useRef<any>(null);
  let map = React.useRef<any>(null);
  // const drawControlRef = React.useRef<any>(null);
  const customMapControl = React.useRef<any>(null);
  const popupRef = React.useRef<HTMLDivElement>();
  const popupContainerRef = React.useRef<mapboxgl.Popup | null>(
    new mapboxgl.Popup({
      closeOnClick: true,
      closeOnMove: false,
      closeButton: true,
      focusAfterOpen: false,
      anchor: 'top',
      offset: [0, 1],
      maxWidth: window.innerWidth.toString() + 'px',
    })
  );
  const [isPopupOpen, setIsPopupOpen] = React.useState<boolean>(false);
  const [popupSourceLatLng, setPopupSourceLatLng] = React.useState<any>(null);
  const [popupTargetLatLng, setPopupTargetLatLng] = React.useState<any>(null);
  const clickedRef = React.useRef<any>(false);
  const hasRTLTextPlugin = React.useRef<boolean>(false);
  const mapLanguage = React.useRef<any>(null);
  const [isStyleLoaded, setIsStyleLoaded] = React.useState<boolean>(false);
  const [activeStyle, setActiveStyle] = React.useState<string>(
    'mapbox://styles/mapbox/dark-v11?optimize=true'
  );
  const [imagesLoadedCounter, setImagesLoadedCounter] =
    React.useState<number>(0);
  const [flyToLocation, setFlyToLocation] = React.useState<LngLatLike>();
  const escKeyPressed = useKeyPress('Escape');
  const currentSources = React.useRef<any>([]);
  const currentLayers = React.useRef<any>([]);
  const contextMenuRef = React.useRef<HTMLDivElement>();
  const contextMenuContainerRef = React.useRef<any>(
    new mapboxgl.Popup({
      closeOnClick: true,
      closeOnMove: true,
      closeButton: false,
      anchor: 'top-left',
      offset: [0, 0],
    })
  );
  const { canWrite } = usePermissions();
  const step = React.useRef<number>(0);
  const animating = React.useRef<boolean>(false);

  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY!;

  const GeocoderControl = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    collapsed: false,
    clearAndBlurOnEsc: true,
    clearOnBlur: true,
    marker: false,
  });

  const canWriteLocation = canWrite('LOCATION');

  const changeDrawMode = (mode: string) => {
    if (onDrawModeChanged) onDrawModeChanged(mode);
  };

  useEffect(() => {
    if (!map.current) return;
    removeCustomMapControl(map.current);
    addCustomMapControl(map.current);
  }, [canWriteLocation]);

  useEffect(() => {
    if (!hasDraw || !hasControls) return;
    if (escKeyPressed) {
      if (drawMode === 'draw_pipeline') {
        if (onDrawModeExited) onDrawModeExited();
      }
      if (drawMode === 'draw_point') {
        const features = Draw?.current?.getAll();
        if (onDrawModeExited) onDrawModeExited();
        changeDrawMode('simple_select');
        removeCustomMapControl(map.current);
        addCustomMapControl(map.current);
      }
      setTimeout(() => {
        // FIXME: this is a hack to delete all drawn features
        Draw?.current?.deleteAll();
      }, 0);
      map.current.getCanvas().style.cursor = '';
    }
  }, [escKeyPressed]);

  const getActualMapStyle = (mapStyle: string) => {
    if (mapStyle === 'googleSatellite') {
      return 'mapbox://styles/kylepallosnapgis/cm16udaal01ze01pbbq605zdl';
    }
    return mapStyle;
  };

  useEffect(() => {
    if (map.current) return; // wait for map to initialize

    if (DIR.RTL === direction && !hasRTLTextPlugin.current) {
      registerMapboxRTLPlugin();
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapStyle
        ? getActualMapStyle(mapStyle)
        : activeStyle
        ? getActualMapStyle(activeStyle)
        : 'mapbox://styles/mapbox/dark-v11',
      zoom,
      minZoom,
      maxZoom,
      projection: {
        name: projection,
      },
      dragPan,
      keyboard,
      // dragRotate: false,
      antialias: false,
      ...(show3D ? { pitch: 60, bearing: 41 } : {}),
    });
    if (mapRef) {
      mapRef.current = map.current;
    }
    if (mapStyle) setActiveStyle(getActualMapStyle(mapStyle));

    // console.log('LANG', lang);
    // mapLanguage.current = new MapboxLanguage({ defaultLanguage: lang });
    // if (mapLanguage.current) {
    //   console.log('SUPPORTED LANG', mapLanguage.current?.supportedLanguages);
    //   map.current.addControl(mapLanguage.current);
    // }

    // if (!map.current.hasControl(GeocoderControl)) {
    //   map.current.addControl(GeocoderControl, 'top-left');
    // }

    contextMenuRef.current?.addEventListener('wheel', (event: any) => {
      map.current.scrollZoom.wheel(event);
    });

    popupRef.current?.addEventListener('wheel', (event: any) => {
      map.current.scrollZoom.wheel(event);
    });

    popupContainerRef.current?.on('open', () => {
      // if (onPopupOpen) onPopupOpen();
      setIsPopupOpen(true);
    });
    popupContainerRef.current?.on('close', (e: any) => {
      setIsPopupOpen(false);
      clickedRef.current = false;
      if (onPopupClose) onPopupClose(e);

      // the click listener should be registered in here
      // but it will keep being added to the map, and it loses the reference
      // to the func. So I cannot .off whenever a new popup opens
      // map.current.on('click', (e: any) => {
      //   if (onClickAnywhere) onClickAnywhere();
      // });
    });

    if (clickableLayers && clickableLayers.length > 0) {
      map.current?.on('click', clickableLayers, (e: any) => {
        clickedRef.current = true;
        if (!popupContainerRef.current?.isOpen()) {
          if (onClick) onClick(e, map.current);
        }
      });
      map.current?.on('touchend', clickableLayers, (e: any) => {
        clickedRef.current = true;
        if (!popupContainerRef.current?.isOpen()) {
          if (onClick) onClick(e, map.current);
        }
      });
      map.current?.on('click', (e: any) => {
        e.features = map.current.queryRenderedFeatures(e.point);
        if (onClickAnywhere) onClickAnywhere(e, map.current);
      });
      map.current?.on('touchend', (e: any) => {
        e.features = map.current.queryRenderedFeatures(e.point);
        if (onClickAnywhere) onClickAnywhere(e, map.current);
      });
      map.current.on('mousemove', clickableLayers, (e: any) => {
        map.current.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      map.current.on('mouseleave', clickableLayers, (e: any) => {
        map.current.getCanvas().style.cursor = '';
      });
    }

    if (hoverableLayers && hoverableLayers.length > 0) {
      map.current.on('mousemove', hoverableLayers, (e: any) => {
        const { features } = e;
        map.current.getCanvas().style.cursor = 'pointer';
        if (clickedRef.current) return;
        if (features.length > 0) {
          if (onHover) onHover(e, map.current);
        }
      });

      // Change it back to a pointer when it leaves.
      map.current.on('mouseleave', hoverableLayers, (e: any) => {
        map.current.getCanvas().style.cursor = '';
        if (clickedRef.current) return;
        popupContainerRef.current?.remove();
      });
    }

    if (initialBounds && initialBounds[0] && initialBounds[0].length > 0) {
      map.current?.fitBounds(initialBounds, { maxDuration: 1000 });
    }
  }, []);

  useEffect(() => {
    if (flyTo) {
      setFlyToLocation(flyTo);
    }
  }, [flyTo]);

  useEffect(() => {
    if (flyToLocation) {
      map.current.flyTo(flyToLocation);
    }
  }, [flyToLocation]);

  useEffect(() => {
    if (activeStyle && map.current) {
      if (isStyleLoaded) map.current.setStyle(activeStyle);
      setIsStyleLoaded(false);
      if (onStyleChange) onStyleChange(activeStyle);
    }
  }, [activeStyle]);

  useEffect(() => {
    if (mapStyle) setActiveStyle(getActualMapStyle(mapStyle));
  }, [mapStyle]);

  useEffect(() => {
    if (zoom && !bounds) map.current?.setZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    if (center && !bounds) map.current?.setCenter(center);
  }, [center]);

  useEffect(() => {
    if (bounds) {
      map.current?.fitBounds(bounds, { maxDuration: 1000 });
    }
  }, [bounds]);

  useEffect(() => {
    if (maxZoom) map.current?.setMaxZoom(maxZoom);
  }, [maxZoom]);

  useEffect(() => {
    if (projection) map.current?.setProjection({ name: projection });
  }, [projection]);

  useEffect(() => {
    if (typeof maxBounds === 'boolean')
      map.current?.setMaxBounds(map.current?.getBounds());
  }, [map.current?.bounds]);

  useEffect(() => {
    if (!map.current || !Draw.current) return;

    if (!!drawCustomFeatures) {
      Draw.current.set(drawCustomFeatures);
    }
  }, [drawCustomFeatures]);

  useEffect(() => {
    if (!!deleteFeatureById) {
      Draw?.current?.delete(deleteFeatureById);
    }
  }, [deleteFeatureById]);

  const setMaxBounds = () => {
    if (!maxBounds) return;

    if (typeof maxBounds === 'boolean')
      map.current?.setMaxBounds(map.current?.getBounds());
    else if (typeof maxBounds === 'object')
      map.current?.setMaxBounds(maxBounds);
  };

  useEffect(() => {
    if (!resetDrawnFeatures) return;
    if (!Draw) return;
    if (!map.current) return;
    if (!hasControls) return;

    removeCustomMapControl(map.current);
    addCustomMapControl(map.current);
    setTimeout(() => {
      // FIXME: this is a hack to delete all drawn features
      Draw?.current?.deleteAll();
    }, 0);
  }, [resetDrawnFeatures]);

  // useEffect(() => {
  //   if (!map.current || !isStyleLoaded) return;
  //   if (!!lang) {
  //     console.log('CHANGE LANG', lang);
  //     changeMapLanguage(map.current, lang);
  //   }
  // }, [lang, isStyleLoaded]);

  // const changeMapLanguage = (map: mapboxgl.Map, lang: string) => {
  //   mapLanguage.current.setLanguage(map.getStyle(), lang);
  //   map.getStyle().layers.forEach((layer: mapboxgl.Layer) => {
  //     if (layer.id.endsWith('-label')) {
  //       map.setLayoutProperty(layer.id, 'text-field', [
  //         'coalesce',
  //         ['get', `name_${lang}`],
  //         ['get', `name_en`],
  //       ]);
  //     }
  //   });
  // };

  const isFeaturePoint = (feature: any) => {
    return feature && feature.geometry && feature.geometry.type === 'Point';
  };

  const drawModeClicked = () => {
    if (!map || !hasControls || !hasDraw) return;
    removeCustomMapControl(map.current);
    addCustomMapControl(map.current, 'drawLocationControl');
    changeDrawMode('draw_point');
  };

  const addCustomMapControl = (
    currentMap: mapboxgl.Map,
    active: string = ''
  ) => {
    if (!hasControls) return;
    const divider = document.createElement('hr');
    divider.className = 'mapboxgl-controls-custom-divider';

    // if (!currentMap.hasControl(customMapControl.current)) {
    //   // TODO: MapCustomControls probably needs some refactoring to be more extensible and flexible
    //   customMapControl.current = new MapCustomControls(
    //     [
    //       // TODO: MapControls should be moved out - Map component shouldn't have any knowledge of permissions
    //       ...(canWriteLocation
    //         ? [
    //             DrawLocationControl(
    //               null,
    //               'drawLocationControl' === active,
    //               drawModeClicked
    //             ),
    //             divider,
    //           ]
    //         : []),
    //       StyleSelectorControl(
    //         activeStyle,
    //         (style: string) => setActiveStyle(style),
    //         direction
    //       ),
    //       ZoomInControl(currentMap),
    //       ZoomOutControl(currentMap),
    //     ],
    //     'rtl' === direction
    //       ? controlStyles?.bottomLeft
    //       : controlStyles?.bottomRight
    //   );

    //   map.current.addControl(
    //     customMapControl.current,
    //     'rtl' === direction ? 'bottom-left' : 'bottom-right'
    //   );
    // }
  };

  const removeCustomMapControl = (currentMap: mapboxgl.Map) => {
    if (!hasControls) return;
    if (currentMap.hasControl(customMapControl.current)) {
      currentMap.removeControl(customMapControl.current);
    }
  };

  useEffect(() => {
    if (!map.current) return;

    map.current.on('load', (e: any) => {
      map.current.resize();
      if (onLoad) onLoad(map.current);
      addImages();
      setIsStyleLoaded(true);
      map.current.getCanvas().focus();

      map.current.getCanvas().addEventListener(
        'keydown',
        (e: any) => {
          if (e.which === 65) {
            // left (a)
            e.preventDefault();
            map.current.panBy([-deltaDistance, 0], {
              easing: easing,
            });
          } else if (e.which === 68) {
            // right (d)
            e.preventDefault();
            map.current.panBy([deltaDistance, 0], {
              easing: easing,
            });
          }
        },
        true
      );
    });

    map.current.on('styledataloading', () => {
      setIsStyleLoaded(false);
    });

    map.current.on('style.load', () => {
      map.current.setFog({
        range: [-1, 1],
        'horizon-blend': 0.01,
        color: 'transparent',
        'high-color': '#616161',
        'space-color': '#252525',
        'star-intensity': 0.2,
      });
      // if (mapStyle && mapStyle === 'mapbox://styles/mapbox/satellite-v9') {
      //   map.current.addSource('mapbox-dem', {
      //     'type': 'raster-dem',
      //     'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
      //     'tileSize': 1024,
      //     'maxzoom': 14
      //   });
      //   map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
      // }

      if (!isStyleLoaded) {
        addImages();
        setIsStyleLoaded(true);
      }
    });

    map.current.on('zoomend', (e: any) => {
      if (onZoomChanged) onZoomChanged(e.target.getZoom());
    });

    map.current.on('moveend', (e: any) => {
      if (onCenterChanged) onCenterChanged(e.target.getCenter());
    });

    map.current.on('contextmenu', (e: any) => {
      e.features = map.current.queryRenderedFeatures(e.point);
      if (onRightClick)
        onRightClick(e, {
          createNewLocation: () => handleCreateNewLocation(e),
          centerMap: () => {
            map.current.flyTo({
              center: e.lngLat,
              essential: true,
              zoom: Math.min(map.current?.getZoom() + 3, 22),
            });
          },
        });
    });
  }, []);

  const handleCreateNewLocation = (e: any, openSidebar = true) => {
    {
      let latLng = [e.lngLat.lng, e.lngLat.lat];
      if (e.features[0]?.layer?.id === 'places') {
        latLng = e.features[0]?.geometry?.coordinates;
      }
      const name = e.features[0]?.properties?.name ?? '';
      drawModeClicked();
      setTimeout(() => {
        Draw?.current?.deleteAll();
        Draw?.current?.add({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: latLng,
          },
          properties: {
            name: name,
          },
        });
        if (openSidebar) {
          map.current.fire('draw.create', {
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: latLng,
                },
              },
            ],
          });
        }
      }, 0);

      map.current.flyTo({
        center: e.lngLat,
        essential: true,
        zoom: map.current?.getZoom(),
      });

      if (onDrawModeExited) onDrawModeExited();
      changeDrawMode('simple_select');
      removeCustomMapControl(map.current);
      addCustomMapControl(map.current);
    }
  };

  const registerMapboxRTLPlugin = () => {
    if (!!hasRTLTextPlugin.current) return;
    hasRTLTextPlugin.current = true;
    try {
      if (
        mapboxgl.getRTLTextPluginStatus() === 'unavailable' ||
        mapboxgl.getRTLTextPluginStatus() !== 'loaded'
      ) {
        mapboxgl.setRTLTextPlugin(
          'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
          console.log,
          false // Lazy load the plugin
        );
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (!map.current || !Draw) return;

    if (DIR.RTL === direction && !hasRTLTextPlugin.current) {
      registerMapboxRTLPlugin();
    }

    if (!map.current.hasControl(Draw.current)) {
      addCustomMapControl(map.current);
      map.current.addControl(Draw.current, 'top-left');
      // map.current.addControl(
      //   new mapboxgl.NavigationControl({
      //     visualizePitch: true,
      //     showZoom: false,
      //   }),
      //   'top-right'
      // );
      if ('rtl' === direction) {
        map.current.addControl(ScaleControl, 'bottom-right');
      } else {
        map.current.addControl(ScaleControl, 'bottom-left');
      }
      map.current.on('draw.create', (e: any) => {
        let { features, mode } = e;
        features.map((feature: any) => {
          if (isFeaturePoint(feature)) {
            const { geometry } = feature;
            feature.projection = map.current.project(geometry.coordinates);
          }
        });
        if (onDraw) onDraw('create', features, Draw?.current?.getAll(), mode);
      });
      map.current.on('draw.update', (e: any) => {
        if ('move' === e.action) {
          let { features } = e;
          if (onDraw) onDraw('move', features, Draw?.current?.getAll());
        }
        if ('change_coordinates' === e.action) {
          let { features } = e;
          if (onDraw)
            onDraw('change_coordinates', features, Draw?.current?.getAll());
        }
      });
      map.current.on('draw.delete', (e: any) => {
        let { features } = e;
        features.map((feature: any) => {
          if (isFeaturePoint(feature)) {
            changeDrawMode('simple_select');
            removeCustomMapControl(map.current);
            addCustomMapControl(map.current);
            setTimeout(() => {
              Draw?.current?.deleteAll();
            }, 0);
          }
        });
        if (onDraw) onDraw('delete', features, Draw?.current?.getAll());
      });
      map.current.on('draw.updateSource', (e: any) => {
        if (onUpdateSource) onUpdateSource(e);
      });
      map.current.on('draw.resetSource', (e: any) => {
        if (onResetSource) onResetSource(e);
      });
    }
  }, [map.current, Draw, hasControls, hasDraw, onDraw, direction]);

  useEffect(() => {
    const qs = (() => {
      // if ('rtl' === direction)
      //   return '.mapboxgl-ctrl-bottom-left .mapboxgl-control-custom-controls .left';
      // return '.mapboxgl-ctrl-bottom-right .mapboxgl-control-custom-controls .right';
      return '.mapboxgl-ctrl-bottom-right .mapboxgl-control-custom-controls';
    })();
    const el = document.querySelector(qs) as HTMLElement;
    if (el) {
      el.style.cssText =
        'rtl' === direction
          ? controlStyles?.bottomLeft
          : controlStyles?.bottomRight;
      el.style.transitionDuration = '0.2s';
    }
  }, [controlStyles, direction]);

  useEffect(() => {
    if (!map.current || !isStyleLoaded || !drawMode) return;

    if (drawModeExtra) {
      Draw.current?.changeMode(drawMode, drawModeExtra);
    } else {
      Draw.current?.changeMode(drawMode);
    }
  }, [isStyleLoaded, drawMode, drawModeExtra]);

  const addImages = () => {
    if (!map.current) return;
    if (!images || images.length < 1) return;

    images.map(image => {
      if (!image || !image.src || !image.id) return;

      try {
        if (image?.type === 'function') {
          if (map.current?.hasImage(image?.id)) {
            return;
          }
          map.current.addImage(image.id, image.src, { pixelRatio: 2 });
          return;
        }

        map.current?.loadImage(image?.src, (err: any, img: any) => {
          if (!map.current?.hasImage(image?.id)) {
            if (image?.type === 'svg') {
              let img = image?.size
                ? new Image(image.size, image.size)
                : new Image(64, 64);
              img.onload = () => {
                if (!map.current.hasImage(image.id))
                  map.current.addImage(
                    image.id,
                    img,
                    // @ts-ignore
                    { ...image.props } || null
                  );
              };
              img.src = image.src;
            } else {
              map.current.addImage(image.id, img, { sdf: !!image.sdf });
            }
          }
        });
      } catch (e: any) {}
    });
  };

  const diffArray = (a: any, b: any) => {
    return new Set([...a].filter(element => !b.includes(element)));
  };

  useEffect(() => {
    return () => {
      console.log('useEffect remove map');
      try {
        if (map.current) map.current?.remove();
      } catch (e) {
        console.log('Error removing map', e);
      }
    };
  }, []); // Empty dependency array ensures this runs only on mount and unmount

  useEffect(() => {
    if (!map.current) {
      // console.log("Map object is not available.");
      return;
    }

    const removeOutdatedSources = () => {
      // console.log("Removing outdated sources...");
      const outdatedSources = diffArray(
        currentSources.current,
        sources?.map(source => source?.id)
      );
      outdatedSources.forEach(sourceId => {
        if (map.current.getSource(sourceId)) {
          // console.log(`Removing layers associated with source: ${sourceId}`);
          const layersToRemove = layers?.filter(
            (layer: any) => layer.source === sourceId
          );
          layersToRemove?.forEach((layer: any) => {
            map.current.removeLayer(layer.id);
            currentLayers.current = currentLayers.current.filter(
              (layer: any) => layer !== layer.id
            );
            // console.log(`Removed layer: ${layer.id}`);
          });
          map.current.removeSource(sourceId);
          currentSources.current = currentSources.current.filter(
            (source: any) => source !== source.id
          );
          // console.log(`Removed source: ${sourceId}`);
        }
      });
    };

    const addOrUpdateSources = () => {
      sources?.forEach(source => {
        if (!source || !source.id || !source.data) {
          // console.log("Skipping invalid source");
          return;
        }
        // if (source.id === 'mapbox-dem') {
        //   if (!map.current.getSource('mapbox-dem')) {
        //     map.current.addSource('mapbox-dem', {
        //       'type': 'raster-dem',
        //       'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        //       'tileSize': 512,
        //       'maxzoom': 18
        //     });
        //     map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 15 });
        //     currentSources.current = [...currentSources.current, source.id];
        //   }
        //   return;
        // }
        if (!map.current.getSource(source.id)) {
          map.current.addSource(source.id, source.data);
          currentSources.current = [...currentSources.current, source.id];
          // console.log(`Added source: ${source.id}`);
        } else {
          map.current.getSource(source.id).setData(source.data.data);
          // console.log(`Updated source: ${source.id}`);
        }
      });
      addLayers();
      orderLayers();
    };

    const addLayers = () => {
      layers?.forEach((layer: any) => {
        if (!layer || !layer.id || !layer.source) {
          // console.log("Skipping invalid layer");
          return;
        }
        if (
          !map.current.getLayer(layer.id) &&
          map.current.getSource(layer.source)
        ) {
          map.current.addLayer(layer);
          currentLayers.current = [...currentLayers.current, layer.id];
          // console.log(`Added layer: ${layer.id}`);
        }
      });
    };

    const removeOutdatedLayers = () => {
      const outdatedLayers = diffArray(
        currentLayers.current,
        layers?.map((layer: any) => layer?.id)
      );
      outdatedLayers.forEach(layerId => {
        if (map.current.getLayer(layerId)) {
          map.current.removeLayer(layerId);
          currentLayers.current = currentLayers.current.filter(
            (layer: any) => layer !== layerId
          );
        }
      });
    };

    if (!isStyleLoaded) {
      return;
    }

    const styleLayers = map.current.getStyle().layers;
    let firstSymbolId;
    for (const layer of styleLayers) {
      if (
        layer.type === 'symbol' ||
        ['admin', 'road'].includes(layer['source-layer'])
      ) {
        firstSymbolId = layer.id;
        break;
      }
    }

    if (mapStyle === 'googleSatellite') {
      if (!map.current.getSource('google-satellite')) {
        map.current.addSource('google-satellite', {
          type: 'raster',
          tiles: [
            'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&scale=2&key=' +
              process.env.REACT_APP_GOOGLE_MAPS_KEY,
          ],
          tileSize: 256,
        });
      }

      if (!map.current.getLayer('google-satellite')) {
        map.current.addLayer(
          {
            id: 'google-satellite',
            type: 'raster',
            source: 'google-satellite',
            paint: {},
          },
          firstSymbolId
        );
      }

      map.current.style.stylesheet.layers.forEach(function (layer: any) {
        if (layer.id === 'satellite') {
          map.current.setLayoutProperty(layer.id, 'visibility', 'none');
        }
      });
    } else {
      map.current.style.stylesheet.layers.forEach(function (layer: any) {
        if (layer.id === 'satellite') {
          map.current.setLayoutProperty(layer.id, 'visibility', 'visible');
        }
      });
      if (map.current.getSource('google-satellite')) {
        map.current.removeSource('google-satellite');
      }
      if (map.current.getLayer('google-satellite')) {
        map.current.removeLayer('google-satellite');
      }
    }

    removeOutdatedLayers();
    removeOutdatedSources();
    addOrUpdateSources();

    // console.log("Starting main logic of useEffect...");
    map.current.on('idle', () => {
      // addLayers();
      orderLayers();
      map.current.off('idle');
    });

    return () => {
      // console.log("Cleaning up...");
      map.current.off('idle');
    };
  }, [images, layers, sources, isStyleLoaded]);

  const orderLayers = () => {
    currentLayers?.current?.sort(
      // @ts-ignore
      (a: any, b: any) => layers?.indexOf(a) - layers?.indexOf(b)
    );
    currentLayers?.current?.forEach((layer: string, index: number) => {
      if (map.current?.getLayer(layer)) {
        if (index < currentLayers?.current?.length) {
          map.current?.moveLayer(layer, currentLayers?.current[index + 1]);
        }
      }
    });
  };

  useEffect(() => {
    if (!popup) {
      popupContainerRef.current?.addClassName(POPUP_HIDDEN_CLASS);
      return;
    }

    popupContainerRef.current?.removeClassName(POPUP_HIDDEN_CLASS);

    if (
      popupContainerRef.current &&
      popupContainerRef.current.getLngLat() !== popup.lngLat
    ) {
      setPopupSourceLatLng(popupContainerRef.current?.getLngLat());
      setPopupTargetLatLng(popup.lngLat);
    }

    if (!popupContainerRef.current?.getLngLat()) {
      popupContainerRef.current?.setLngLat(popup.lngLat);
    }

    popupContainerRef.current?.setDOMContent(popupRef.current!);

    if (!!popup.hover) {
      popupContainerRef.current?.removeClassName(POPUP_DEFAULT_CLASS);
      popupContainerRef.current?.addClassName(POPUP_DEFAULT_CLASS_HOVER);
    } else {
      popupContainerRef.current?.removeClassName(POPUP_DEFAULT_CLASS_HOVER);
      popupContainerRef.current?.addClassName(POPUP_DEFAULT_CLASS);
    }
    if (popup.className)
      popupContainerRef.current?.addClassName(popup.className);

    if (!popupContainerRef.current?.isOpen()) {
      popupContainerRef.current?.addTo(map.current);
    }

    if (popup.external) {
      clickedRef.current = true;
    }

    if (!!popup.shouldFlyTo) {
      if (popup.featureBox) {
        map.current.fitBounds(popup.featureBox);
        if (popup.isMobile) {
          const tmpZoom = map.current.getZoom();
          map.current.flyTo({
            center: popup.lngLat,
            zoom: tmpZoom,
            offset: popup.shouldFlyTo?.offset ?? [0, 0],
            essential: true,
          });
        }
      } else {
        map.current.flyTo({
          center: popup.lngLat,
          zoom: Math.max(map.current?.getZoom(), popup.shouldFlyTo?.zoom ?? 12),
          offset: popup.shouldFlyTo?.offset ?? [0, 0],
          essential: true,
        });
      }
    }
  }, [popup]);

  const animationRequestRef: any = React.useRef();
  const animationStartRef: any = React.useRef();
  const [animationElapsedTime, setAnimationElapsedTime] = React.useState(0);

  const animate = (time: any) => {
    if (animationStartRef.current != null) {
      const deltaTime = time - animationStartRef.current;
      setAnimationElapsedTime(deltaTime);
    } else {
      animationStartRef.current = time;
    }
    animationRequestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (!popupTargetLatLng) return;

    if (!animationRequestRef.current && isPopupOpen)
      animationRequestRef.current = requestAnimationFrame(animate);
  }, [popupTargetLatLng]);

  useEffect(() => {
    if (animationElapsedTime > 0 && popupSourceLatLng && popupTargetLatLng) {
      const interpolator = interpolateNumberArray(
        [popupSourceLatLng.lng, popupSourceLatLng.lat],
        [popupTargetLatLng.lng, popupTargetLatLng.lat]
      );
      const progress = Math.min(
        100,
        (animationElapsedTime * 100) / POPUP_ANIMATION_DURATION
      );
      const interpolatedValues = interpolator(progress / 100);
      popupContainerRef.current?.setLngLat(interpolatedValues as LngLatLike);
    }

    if (animationElapsedTime >= POPUP_ANIMATION_DURATION) {
      cancelAnimationFrame(animationRequestRef.current);
      animationRequestRef.current = null;
      animationStartRef.current = null;
      setAnimationElapsedTime(0);
    }
  }, [animationElapsedTime, popupSourceLatLng, popupTargetLatLng]);

  useEffect(() => {
    if (!contextMenu) return;

    contextMenuContainerRef.current.setDOMContent(contextMenuRef.current);
    contextMenuContainerRef.current.setLngLat(contextMenu.lngLat);
    contextMenuContainerRef.current.addClassName('context-menu');
    contextMenuContainerRef.current.addTo(map.current);
  }, [contextMenu]);

  return (
    <>
      <div
        ref={mapContainer}
        className={`map-container ${customContainerClass}`}
        style={{ width, height, ...style }}>
        {'googleSatellite' === mapStyle && (
          <div style={googleAttributionStyle}>
            <img src='/google_on_white.png' />
          </div>
        )}
      </div>
      <div>
        <div ref={el => (popupRef.current = el!)}>{popup?.el}</div>
        <div ref={el => (contextMenuRef.current = el!)}>{contextMenu?.el}</div>
      </div>
      {!!CustomControls && CustomControls(map.current)}
    </>
  );
}

export default AltMap;
// export * from './constants';
